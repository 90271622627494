$(document).ready(function() {
    //test
    if ($('.slides--slider').length != false) {
        if (!$('.slides--slider').hasClass('swiper-container-initialized')) {
            new Swiper('.slides--slider', {
                slidesPerView: 4,
                loop: false,
                navigation: {
                    nextEl: '.slide-next',
                    prevEl: '.slide-prev'
                },
                breakpoints: {
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 4,
                    },
                }
            });
        }
    }

	$(document).ready(function(){
        $('.block-filter-options .block-filter-option').matchHeight(
            {
                byRow: false
            }
        );
    });

// Dit zorgt voor vreemde errors in de javascript logging.
//    $(document).on('click', 'a[href^="#"]', function (event) {
//        event.preventDefault();
//
//        $('html, body').animate({
//            scrollTop: $($.attr(this, 'href')).offset().top - 175
//        }, 500);
//    });

    $(document).ready(function(){
      $('body').scrollspy({target: ".blueprint-header--items .nav", offset: 250});
    });
    var $document = $(document),
        $element = $('nav#gopartsnav'),
        className = 'scrolled';

    $document.scroll(function() {
      if ($document.scrollTop() > 1) {
        $element.addClass(className);
      } else {
        $element.removeClass(className);
      }
    });

	$(document).ready(function(){
        var isMobile = window.matchMedia("only screen and (min-width: 992px)").matches;

        if (isMobile) {
            $('#blueprint').smoothZoom({

                width: "100%",
                pan_BUTTONS_SHOW: "NO",
                pan_LIMIT_BOUNDARY: "NO",
                button_SIZE: 24,
                button_ALIGN: "top right",
                zoom_MAX: 500,
                responsive: true,
                border_TRANSPARENCY: 20,
            });
		}
	});

    apply_stickies();

    window.addEventListener('scroll', function() {
        apply_stickies();
    })

    function apply_stickies() {
        var _$stickies = [].slice.call(document.querySelectorAll('.header-holder, .blueprint-blueprint'))
        _$stickies.forEach(function(_$sticky) {
            if (CSS.supports && CSS.supports('position', 'sticky')) {
                apply_sticky_class(_$sticky);
            }
        })
    }

    function apply_sticky_class(_$sticky) {
        var currentOffset = _$sticky.getBoundingClientRect().top;
        var stickyOffset = parseInt(getComputedStyle(_$sticky).top.replace('px', ''));
        var isStuck = currentOffset <= stickyOffset;

        _$sticky.classList.toggle('p-sticky', isStuck);
    }

    // Tax toggle switch
    $('.toggleswitch').click(function() {
        //alert('click');
        if($('.price-sale').length != false) {
           // console.log( "price sale deel deel");
            // Checkbox
            var checkbox = $(this).siblings('input[type=checkbox]');
            if (checkbox.attr('checked') == false || !checkbox.attr('checked')) {
                checkbox.attr('checked', true);
                checkbox.parent().addClass('tax-checked');

                $('.productSalePriceEx').animate({
                    top: '-100%'
                }, 'linear');
                $('.productSalePriceInc').animate({
                    top: 0
                }, 'linear');
                
                $('.toggleswitch .toggleBox').animate({
                    left: '-2.5px'
                });
                       //console.log( "price sale deel klaar");
            } else {
                checkbox.attr('checked', false);
                checkbox.parent().removeClass('tax-checked');
    
                $('.toggleswitch .toggleBox').animate({
                    left: '50%'
                });
                
                $('.productSalePriceEx').animate({
                    top: 0
                }, 'linear');
                $('.productSalePriceInc').animate({
                    top: '100%'
                }, 'linear');
            }
        } else if($('body.search')[0]) {
            //console.log( "body deel");
            $('tr.product-row').each(function() {
                $(this).find('.productSalePriceEx').toggle();
                $(this).find('.productSalePriceInc').toggle();
            })

            // Checkbox
            var checkbox = $(this).siblings('input[type=checkbox]');
            if (checkbox.attr('checked') == false || !checkbox.attr('checked')) {
                checkbox.attr('checked', true);
                checkbox.parent().addClass('tax-checked');
                $('.toggleswitch .toggleBox').animate({
                    left: '-2.5px'
                });
            } else {
                checkbox.attr('checked', false);
                checkbox.parent().removeClass('tax-checked');
                $('.toggleswitch .toggleBox').animate({
                    left: '50%'
                });
            }
        } else {
           //console.log( "niet body deel");
            $('.blueprint-product').each(function() {
                var otherPrice = $(this).find('.productSalePrice').text();
                $(this).find('.productSalePrice').text($(this).find('.productSalePrice').attr('data-otherPrice'));
                $(this).find('.productSalePrice').attr('data-otherPrice', otherPrice);
                $(this).find('.productPriceExText').toggle();
                $(this).find('.productPriceIncText').toggle();
            })

            // Checkbox
            var checkbox = $(this).siblings('input[type=checkbox]');
            if (checkbox.attr('checked') == false || !checkbox.attr('checked')) {
                //console.log( "checkedfalse binnen niet body deel");

                checkbox.attr('checked', true);
                checkbox.parent().addClass('tax-checked');
                $('.toggleswitch .toggleBox').animate({
                    left: '-2.5px'
                });
            } else {
                //console.log( "checkedtrue binnen niet body deel");

                checkbox.attr('checked', false);
                checkbox.parent().removeClass('tax-checked');
                $('.toggleswitch .toggleBox').animate({
                    left: '50%'
                });
            }
          //  console.log( "niet body deel klaar");

        }
    });
// Geeft issues op tablets.
//    // Fly to cart
//    $('.blueprint-product-addToCart').click(function() {
//
//        var cartPos = $('#cartFullContent').offset();
//        var object = $(this).closest('.blueprint-product-addToCart');
//
//        $('body').append('<div class="flyToCart"><div class="btn btn-success btn-sm addtocart"><i class="fa fa-shopping-cart"></i></div></div>');
//        $('.flyToCart').css({
//            left: object.offset().left,
//            top: object.offset().top
//            //            width: object.width()
//        });
//        $('.flyToCart').animate({
//            left: cartPos.left - -20,
//            top: cartPos.top - -2,
//        }, 500, function() {
//            $(this).fadeOut(500, function() {
//                $(this).remove();
//            });
//        });
//        setTimeout(function() {
//            $('.flyToCart').addClass('animated');
//        }, 500);
//    });
//
//    $('.addtocart').click(function() {
//
//        var cartPos = $('#cartFullContent').offset();
//        var object = $(this);
//
//        $('body').append('<div class="flyToCart"><div class="btn btn-success btn-sm addtocart"><i class="fa fa-shopping-cart"></i></div></div>');
//        $('.flyToCart').css({
//            left: object.offset().left,
//            top: object.offset().top
//            //            width: object.width()
//        });
//        $('.flyToCart').animate({
//            left: cartPos.left - -20,
//            top: cartPos.top - -2,
//        }, 500, function() {
//            $(this).fadeOut(500, function() {
//                $(this).remove();
//            });
//        });
//        setTimeout(function() {
//            $('.flyToCart').addClass('animated');
//        }, 500);
//    })
    //scroll up
    $(window).scroll(function() {
        if ($(this).scrollTop()) {
            $('.go-up').fadeIn();
        } else {
            $('.go-up').fadeOut();
        }
    });

    $('.go-up').click(function() {
        $('html, body').animate({scrollTop: '0px'}, 300);
    });

    // Sidebar
    $('#btn-global-menu, .sidebar--close, .sidebarBackground').click(function() {
        if($('.sidebar').hasClass('active')) {
            $('.sidebarBackground').fadeOut();
            $('.sidebar').animate({
                right: '-100%'
            });
            $('.sidebar').removeClass('active');
        } else {
            $('.sidebarBackground').fadeIn();
            $('.sidebar').animate({
                right: 0
            });
            $('.sidebar').addClass('active');

        }
    });

    $('.sidebar .sidebar--dropdownParent').click(function(e) {
        e.preventDefault();

        var menu = $(this).children('.sidebar--dropdownMenu');

        if(menu.hasClass('active')) {
            menu.slideUp();
            menu.removeClass('active');
        } else {
            menu.slideDown();
            menu.addClass('active');
        }
    });

    $('.categoryFilter--checkbox').each(function() {
        var checkbox = $(this).find('.categoryFilter--fakeCheckbox');

        checkbox.click(function() {
            $(this).toggleClass('active');
        });
    });

    $('.product-miniature--title').matchHeight();
    // $('.product-miniature').matchHeight();
});
